import React from "react";

function CampaignsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 105 107"
        >
            <path
                fill="#fff"
                d="M81.779 72.356a35 35 0 11-52.803-45.954l26.401 22.977L81.78 72.356z"
            ></path>
            <circle
                cx="22"
                cy="85"
                r="22"
                fill="#fff"
                fillOpacity="0.5"
            ></circle>
        </svg>
    );
}

export default CampaignsIcon;
