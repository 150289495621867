import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
export default function WhyPartnerWithUs({
    partnerWitUSData,
}: {
    partnerWitUSData: {
        title: string;
        paragraphs: { text: string; className: string | "default" }[];
        buttonText: string;
    };
}) {
    const mainSectionDevRef = useRef(null);
    const isInView = useInView(mainSectionDevRef, { once: true });
    const firstEl = useAnimation();

    useEffect(() => {
        if (isInView) {
            firstEl.start("visible").catch(() => {});
        }
    }, [isInView]);
    return (
        <motion.section
            ref={mainSectionDevRef}
            className={styles["bgStyle"]}
            variants={{
                visible: {
                    y: [150, 0],
                    opacity: [0, 1],
                    transition: { duration: 1.5 },
                },
            }}
            animate={firstEl}
        >
            <h2 className="font-bold 3xl:text-6xl lg:text-5xl text-3xl lg:leading-[54px] leading-9  ">
                {partnerWitUSData.title}
            </h2>
            {partnerWitUSData.paragraphs.map((p, index) => (
                <p
                    key={index}
                    className={
                        p.className === "default"
                            ? "3xl:mt-6  mt-3 3xl:text-2xl  3xl:leading-10 lg:text-lg  3xl:max-w-[1040px] max-w-[740px]  font-light"
                            : p.className
                    }
                >
                    {p.text}
                </p>
            ))}
            <div className="lg:mt-9 mt-12 w-fit ">
                <SharedButton href="/partner-with-us">
                    {partnerWitUSData.buttonText}
                </SharedButton>
            </div>
        </motion.section>
    );
}
