import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import useWindowSize from "@hooks/useWindowSize";
export default function SharedNumbersInfo({
    info,
}: {
    info: {
        bgColor: string;
        icon: ReactNode;
        title: string;
        description: string;
        deferentStyle?: boolean;
        zIndex?: number;
        animation?: {
            delay?: number;
            rotate?: number[];
            x?: number[];
        };
    };
}) {
    const windowSize = useWindowSize();
    const {
        bgColor,
        description,
        icon,
        title,
        deferentStyle,
        animation,
        zIndex,
    } = info;

    return (
        <motion.li
            className={`rounded-[20px] text-[#fff] 3xl:w-[375px] 3xl:h-[400px] lg:w-[225px] lg:h-[250px] 3xl:p-10 p-5 flex lg:flex-col   justify-between`}
            style={{ backgroundColor: bgColor, zIndex: zIndex }}
            variants={{
                show:
                    windowSize.width < 1024
                        ? {
                              y: [30, 0],
                              opacity: [0, 1],
                              transition: {
                                  delay: animation?.delay,
                                  duration: 1,
                              },
                          }
                        : {
                              x: animation?.x,
                              rotate: animation?.rotate,
                              transition: {
                                  delay: animation?.delay,
                                  duration: 0.5,
                              },
                          },
            }}
        >
            <div
                className={`   w-[73px] h-[68px] lg:w-[100px] lg:h-[100px] 3xl:w-[150px] 3xl:h-[150px]  ${
                    deferentStyle ? "order-2" : "lg:order-1 order-2"
                } `}
            >
                {icon}
            </div>
            <div
                className={`${
                    deferentStyle ? "order-1" : "lg:order-2 order-1"
                } `}
            >
                <h3 className="font-bold 3xl:text-[4.5rem] 3xl:leading-[5.5rem] lg:text-[50px] text-4xl lg:leading-10">
                    {title}
                </h3>
                <p className="font-light 3xl:text-4xl lg:text-2xl text-xl">
                    {description}
                </p>
            </div>
        </motion.li>
    );
}
