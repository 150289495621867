import React from "react";

function LeftArrowIcon({ fill }: { fill: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 9 14"
        >
            <path
                fill={fill}
                fillRule="evenodd"
                d="M7.99.41a1.4 1.4 0 010 1.98L3.38 7l4.61 4.61a1.4 1.4 0 11-1.98 1.98l-5.6-5.6a1.4 1.4 0 010-1.98l5.6-5.6a1.4 1.4 0 011.98 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default LeftArrowIcon;
