import React from "react";

function StaxIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="85"
            height="30"
            fill="none"
            viewBox="0 0 85 30"
        >
            <g clipPath="url(#clip0_1_28701)">
                <path
                    fill="#B93BE4"
                    d="M74.01 3.535v.88l2.464 1.233L74.01 6.88v.88l4.24-2.112-4.24-2.113zm0 5.106v.88l5.134-2.553 5.135 2.553v-.88l-5.135-2.553-5.134 2.553zm5.134-6.088L74.01 0v.88l5.134 2.553L84.28.88V0l-5.135 2.553zM84.28 6v-.88l-2.465-1.232 2.465-1.233v-.88l-4.24 2.113L84.28 6zm-10.27-3.345L84.265 7.76v-.88L74.01 1.775v.88z"
                ></path>
                <path
                    fill="#1D1D1B"
                    d="M26.626 19.98v-4.474h5.985V9.521h-5.985V3.535H20.64v16.43c0 5.517 4.503 10.02 10.034 10.02h1.95v-5.97h-1.95a4.035 4.035 0 01-4.05-4.035zM7.584 17.648l3.022 5.501a.578.578 0 01-.513.866H0V30h11.78c4.18 0 6.836-4.474 4.826-8.127l-3.022-5.501a.578.578 0 01.514-.866h5.237v-5.97h-6.91c-4.195 0-6.85 4.46-4.84 8.112zm65.003-2.142h1.437V9.521h-1.276a11.473 11.473 0 00-8.978 4.327 11.45 11.45 0 00-8.978-4.327h-1.276v5.985h1.438c2.626 0 4.826 1.82 5.427 4.254-.601 2.435-2.801 4.255-5.428 4.255h-1.437V19.57c0-5.751-4.68-10.43-10.445-10.43-5.765 0-10.445 4.679-10.445 10.43C32.626 25.32 37.32 30 43.07 30h3.154v-5.985H43.07a4.455 4.455 0 01-4.46-4.445 4.455 4.455 0 014.46-4.445 4.455 4.455 0 014.46 4.445V30h7.261c3.536 0 6.836-1.628 8.978-4.328A11.45 11.45 0 0072.748 30h1.276v-5.985h-1.437c-2.626 0-4.827-1.82-5.428-4.255a5.56 5.56 0 015.428-4.254z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_28701">
                    <path fill="#fff" d="M0 0H84.279V30H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default StaxIcon;
