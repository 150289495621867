import React from "react";

function QuotationIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 37 25"
        >
            <path
                fill="#B083E0"
                d="M0 16.308C0 13.212 1.368 9.72 4.104 5.832 6.84 1.944 8.892 0 10.26 0c1.296 0 1.944.432 1.944 1.296 0 .36-.324 1.584-.972 3.672-.576 2.016-.864 3.492-.864 4.428 0 .936.252 1.548.756 1.836 2.304 1.584 3.456 3.924 3.456 7.02 0 1.872-.72 3.384-2.16 4.536-1.368 1.152-3.168 1.728-5.4 1.728-4.68 0-7.02-2.736-7.02-8.208zm21.6 0c0-3.096 1.368-6.588 4.104-10.476C28.44 1.944 30.492 0 31.86 0c1.296 0 1.944.432 1.944 1.296 0 .36-.324 1.584-.972 3.672-.576 2.016-.864 3.492-.864 4.428 0 .936.252 1.548.756 1.836 2.304 1.584 3.456 3.924 3.456 7.02 0 1.872-.72 3.384-2.16 4.536-1.368 1.152-3.168 1.728-5.4 1.728-4.68 0-7.02-2.736-7.02-8.208z"
            ></path>
        </svg>
    );
}

export default QuotationIcon;
