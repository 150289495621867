import React, { ReactNode, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import styles from "./style.module.scss";
import RightArrowIcon from "../icons/rightArrowIcon";
import LeftArrowIcon from "../icons/leftArrowIcon";
import QuotationIcon from "../whatClientsSay/icons/quotationIcon";
import style from "./style.module.scss";
import Slider from "react-slick";
export default function ShareSlider({
    list,
    deferentStyle,
    perPage,
}: {
    list: {
        description: string;
        mainLogo?: ReactNode;
        iconName?: ReactNode;
        name?: string;
        url?: string;
    }[];
    deferentStyle: boolean;
    perPage: number;
}) {
    const leftArrowAnimation = {
        scale: [1, 1.5, 1],
        transition: {
            duration: 0.8,
            repeat: Infinity,
            delay: 0.3,
        },
    };

    const rightArrowAnimation = {
        scale: [1, 1.5, 1],
        transition: {
            duration: 0.8,
            repeat: Infinity,
            delay: 0.3,
        },
    };
    const sliderRef = useRef<Slider>(null);
    const next = () => {
        sliderRef.current?.slickNext();
    };
    const previous = () => {
        sliderRef.current?.slickPrev();
    };

    const settings = deferentStyle
        ? {
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: perPage,
              slidesToScroll: 1,
              arrows: false,
              responsive: [
                  {
                      breakpoint: 1024,
                      settings: {
                          dots: true,
                          dotsClass: `slick-dots ${styles["dotsStyle"]} `,
                      },
                  },
                  {
                      breakpoint: 640,
                      settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          dots: true,
                          dotsClass: `slick-dots ${styles["dotsStyle"]} `,
                      },
                  },
              ],
          }
        : {
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: perPage,
              slidesToScroll: 1,
              arrows: false,
              responsive: [
                  {
                      breakpoint: 1024,
                      settings: {
                          dots: true,
                          dotsClass: `slick-dots ${styles["dotsStyle"]} `,
                      },
                  },
              ],
          };

    return (
        <>
            <motion.span
                className={` cursor-pointer p-2 z-1000 hidden sm:block relative  w-[29px] h-[34px] 3xl:w-[39px] 3xl:h-[44px] `}
                onClick={previous}
                whileHover={leftArrowAnimation}
            >
                <LeftArrowIcon fill={"#2B2F34"} />
            </motion.span>
            <div
                className={` ${
                    deferentStyle
                        ? "3xl:max-w-[1080px] max-w-[770px]"
                        : "3xl:max-w-[700px] max-w-[580px]"
                } `}
            >
                <Slider ref={sliderRef} {...settings}>
                    {list.map((el, index) => (
                        <div className={style.slide} key={index}>
                            <div
                                className={
                                    deferentStyle
                                        ? styles[
                                              "sliderMainContainerDeferentStyle"
                                          ]
                                        : styles["sliderMainContainer"]
                                }
                            >
                                <div>
                                    <span
                                        className={`${
                                            deferentStyle
                                                ? "h-[30px] lg:h-[40px] 3xl:h-[50px] 3xl:w-[279px] w-[200px] lg:w-[239px]    relative flex justify-center items-center mx-auto"
                                                : "block relative w-[37px] h-[25px] 3xl:w-[42px] 3xl:h-[30px] "
                                        }`}
                                    >
                                        {deferentStyle ? (
                                            el.mainLogo
                                        ) : (
                                            <QuotationIcon />
                                        )}
                                    </span>
                                    <p
                                        className={`3xl:text-xl font-light 3xl:leading-8 ${
                                            deferentStyle
                                                ? "mt-6 text-center "
                                                : "mt-3"
                                        } `}
                                    >
                                        {el.description}
                                    </p>
                                </div>

                                {!deferentStyle && (
                                    <div className="flex items-center gap-3 mt-4">
                                        <span>{el.iconName}</span>
                                        <div>
                                            <p className="3xl:text-base text-[11px] text-[#474747]">
                                                {el.name}
                                            </p>
                                            {el.url && (
                                                <p className="3xl:text-base text-[11px] text-[#474747] underline">
                                                    {el.url}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <motion.span
                whileHover={rightArrowAnimation}
                onClick={next}
                className={` cursor-pointer p-2 z-1000 hidden sm:block relative  w-[29px] h-[34px] 3xl:w-[39px] 3xl:h-[44px] `}
            >
                <RightArrowIcon fill={"#2B2F34"} />
            </motion.span>
        </>
    );
}
