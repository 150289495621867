import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import SharedNumbersInfo from "./sharedNumbersInfo";
import ClientsNumbersIcon from "./icons/clientsNumbersIcon";
import CampaignsIcon from "./icons/campaignsIcon";
import AdSpendIcon from "./icons/adSpendIcon";
import useWindowSize from "@hooks/useWindowSize";

export default function WeAreGoodWithNumbers() {
    const mainSectionDevRef = useRef(null);
    const windowSize = useWindowSize();
    const list = [
        {
            title: "150+",
            description: "Clients",
            icon: <ClientsNumbersIcon />,
            bgColor: "#8850C3",
            zIndex: 1,
            animation: {
                delay: 0.9,
                rotate: [0, windowSize.width < 2024 ? -10 : -5],
                x: [10000, 30],
            },
        },
        {
            title: "2000+",
            description: "Campaigns",
            icon: <CampaignsIcon />,
            bgColor: "#A872E2",
            deferentStyle: true,
            zIndex: 2,
            animation: {
                delay: 1.4,
                rotate: [0],
                x: [10000, 0],
            },
        },
        {
            title: "$100M+",
            description: "Ads",
            icon: <AdSpendIcon />,
            bgColor: "#BD8CF2",
            zIndex: 3,
            animation: {
                delay: 1.9,
                rotate: [0, windowSize.width < 2024 ? 15 : 10],
                x: [10000, -30],
            },
        },
    ];
    const isInView = useInView(mainSectionDevRef, { once: true });
    const firstEl = useAnimation();
    const secondEl = useAnimation();

    useEffect(() => {
        if (isInView) {
            firstEl.start("visible").catch(() => {});
            secondEl.start("show").catch(() => {});
        }
    }, [isInView]);
    return (
        <motion.section
            ref={mainSectionDevRef}
            className={`bg-[#B083E0]  rounded-[20px] lg:pr-20 lg:pl-12 px-6 3xl:py-24 lg:py-12 pt-16 pb-7 flex flex-col lg:flex-row lg:gap-16 gap-5`}
            style={{ width: windowSize.width < 1024 ? "auto" : 0 }}
            variants={{
                visible:
                    windowSize.width < 1024
                        ? {
                              y: [150, 0],
                              opacity: [0, 1],
                          }
                        : {
                              width: "auto",
                          },
            }}
            transition={{
                duration: windowSize.width < 1024 ? 1.5 : 0.8,
                ease: "linear",
                type: "tween",
            }}
            animate={firstEl}
        >
            <h2 className=" font-bold 3xl:text-[5.5rem] 3xl:leading-[6.5rem] 3xl:min-w-[530px]  lg:text-6xl text-4xl lg:leading-[78px] leading-10 text-[#fff] lg:min-w-[360px] text-center lg:text-left ">
                We're Good with Numbers
            </h2>
            <motion.ul
                variants={{
                    show: {},
                }}
                animate={secondEl}
                className="flex flex-col lg:flex-row gap-3 lg:gap-0 "
            >
                {list?.map((el, index) => (
                    <SharedNumbersInfo key={index} info={el} />
                ))}
            </motion.ul>
        </motion.section>
    );
}
