import React, { useRef, useEffect, CSSProperties, useState } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import Image from "next/image";
import styles from "./style.module.scss";
import SharedButton from "@components/severalbrands/sharedButton";

export default function LogoSlider({
    logos,
    animationDuration,
    showButton,
}: {
    logos: { path: string; slug: string }[];
    animationDuration?: number;
    showButton?: boolean;
}) {
    const mainSectionDevRef = useRef(null);
    const [removeAnimationStyle, setRemoveAnimationStyle] = useState(true);
    const isInView = useInView(mainSectionDevRef, { once: true });
    const firstEl = useAnimation();
    const finalLogos = logos;

    useEffect(() => {
        const startAnimation =
            window.innerWidth < 767
                ? finalLogos.length > 2
                : finalLogos.length > 6;
        if (startAnimation) {
            setRemoveAnimationStyle(false);
            const mainScrolled = document.getElementById("mainSectionDevRef");

            if (mainScrolled) {
                mainScrolled.setAttribute("data-animated", "true");
            }

            const innerScrolled = document.getElementById("innerSectionDevRef");
            if (innerScrolled) {
                const innerScrolledContent = Array.from(innerScrolled.children);
                innerScrolledContent.forEach((item) => {
                    const duplicatedItem = item.cloneNode(true);
                    innerScrolled.appendChild(duplicatedItem);
                });
            }
        }
    }, []);

    useEffect(() => {
        if (isInView) {
            firstEl.start("visible").catch(() => {});
        }
    }, [isInView]);

    const outerSectionStyle = {
        "--animationDuration": `${animationDuration ? animationDuration : 40}s`,
    } as CSSProperties;
    return (
        <motion.section
            ref={mainSectionDevRef}
            id="mainSectionDevRef"
            className={` 3xl:mt-36 lg:mt-32 mt-24    relative  mx-auto ${styles["scrolled"]}`}
            variants={{
                visible: {
                    y: [150, 0],
                    opacity: [0, 1],
                    transition: { duration: 1.5 },
                },
            }}
            animate={firstEl}
            style={outerSectionStyle}
        >
            {!removeAnimationStyle && (
                <div className="hidden lg:block absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-white before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-white after:to-transparent after:filter after:blur-3"></div> //the shadow on both sides on the slider
            )}

            <h2 className="relative z-30 font-bold 3xl:text-6xl lg:text-4xl text-2xl lg:leading-10  text-center ">
                We Collaborate With 600+ Companies in Different Sectors
            </h2>
            <div
                id="innerSectionDevRef"
                className={`mt-7 lg:mt-12  3xl:mt-20  3xl:h-[60px] lg:h-[45px] h-[29px] ${
                    styles["scrolled__inner"]
                } ${removeAnimationStyle ? "justify-center" : ""}`}
            >
                {finalLogos?.map((logo, index) => (
                    <div
                        key={index}
                        className={`w-[120px]  lg:w-[172px] 3xl:w-[250px] relative `}
                    >
                        <div className="relative mx-auto w-[80%] h-[100%] block">
                            <Image
                                src={logo.path}
                                alt={logo.slug}
                                layout="fill"
                                objectFit="contain"
                                loading="eager"
                            />
                        </div>
                    </div>
                ))}
            </div>
            {showButton && (
                <div className="z-30 relative 3xl:mt-20 mt-12 text-center w-fit mx-auto">
                    <SharedButton href="/partner-with-us">
                        Partner With Us
                    </SharedButton>
                </div>
            )}
        </motion.section>
    );
}
