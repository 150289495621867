import TextImageSharedComponent from "@components/severalbrands/homePage/firstSection/textImageSharedComponent";
import style from "./style.module.scss";
import firstImg from "src/components/severalbrands/lotties/simplifyingComplexDecisions4.json";
import deliveringOn from "src/components/severalbrands/lotties/deliveringOn3.json";
const simplifyingComplexDecisions = {
    img: firstImg,
    mainTitle: "Simplifying Complex Decisions",
    secondTitle: "Several Brands: Performance-Based Digital Marketing Experts",
    paragraphs: [
        "In a world of options, we excel at connecting the right products and services with the right customers. We specialize in several industries, speak multiple languages and have the dynamic expertise to grow your business. From B2B to B2C to legal, we make quality lead generation simple.",
    ],
    mainH1Element: true,
};
const deliveringOurPromises = {
    img: deliveringOn,
    mainTitle: "Delivering on Our Promises",
    paragraphs: [
        "We're experts in market and audience research, which means we know how to bring in the clients you need and help you focus on the important stuff. We've worked with plenty of big-name companies and local businesses.",
        "We're proud that our data-driven strategies have brought them measurable growth and a solid return on investment. Whether you’re an attorney, a B2B or B2C enterprise, or an online business, we’ve got the tools and expertise to get you where you need to be.",
    ],
    imgToLeft: true,
    showAnimation: true,
};
export default function FirstSection() {
    return (
        <section className={style["bgStyle"]}>
            <div>
                <div
                    className={`lg:py-28 py-16 3xl:p-0  ${style["hero-section"]}`}
                >
                    <TextImageSharedComponent
                        info={simplifyingComplexDecisions}
                    />
                </div>
                <div className="3xl:pt-5 3xl:pb-56 lg:pb-32 pb-32 ">
                    <TextImageSharedComponent info={deliveringOurPromises} />
                </div>
            </div>
        </section>
    );
}
