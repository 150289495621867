import React from "react";

function WalkerIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <path
                fill="#2B2F34"
                d="M29.96 15c0 8.284-6.707 15-14.98 15S0 23.284 0 15C0 6.716 6.707 0 14.98 0s14.98 6.716 14.98 15z"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M24.254 21.472h1.295L20.582 8.824h-1.347l-3.596 8.934-3.2-8.475h2.557L13.91 12.11l.289.8 1.298-3.628h1.22v-.462h-12.6v.462h1.408l4.45 12.188h.593l1.885-5.209-.284-.786-.97 2.754L7.86 9.284h2.41l4.448 12.188h.704l1.524-3.928h5.411l-.399-1.027h-4.642l2.537-6.488 4.4 11.443z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default WalkerIcon;
