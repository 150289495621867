import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import useWindowSize from "@hooks/useWindowSize";

export default function SharedSlideInfo({
    info,
}: {
    info: {
        title: string;
        description: string;
        img: string;
        bgColor: string;
        zIndex: number;
        yValue: number;
        scaleValue: number;
    };
}) {
    const { bgColor, description, title, zIndex, img, scaleValue, yValue } =
        info;
    const windowSize = useWindowSize();

    return (
        <motion.li
            id={title}
            className={`rounded-[20px]  flex 3xl:h-[630px] lg:h-[480px] h-[540px] gap-6  lg:gap-0  3xl:gap-20 lg:pr-5 3xl:pr-0`}
            style={{
                backgroundColor: bgColor,
                zIndex: zIndex,
                scale: windowSize.width > 1024 ? scaleValue : 1,
                y: windowSize.width > 1024 ? yValue : 0,
            }}
        >
            <div
                className={` pt-14 lg:pt-16 3xl:pt-20 lg:pl-20 px-6 pb-9  max-w-[730px] 3xl:max-w-[1030px] `}
            >
                <h3 className="font-bold 3xl:text-7xl 3xl:leading-[5.5rem] lg:text-5xl text-3xl lg:leading-[3.7rem] leading-9 text-[#37367D]">
                    {title}
                </h3>
                <p className="3xl:text-2xl 3xl:leading-10 lg:text-lg lg:leading-8 mt-5 lg:mt-5 font-light">
                    {description}
                </p>
            </div>
            <div
                className={`w-[281px]  h-[253px] 3xl:w-[740px] 3xl:min-w-[740px] md:w-[580px] md:min-w-[580px]  3xl:h-[630px] md:h-[480px]  relative  mx-auto lg:mx-0 lg:mt-0  3xl:mt-0 hidden lg:block `}
            >
                <Image layout="fill" alt="Image" objectFit="cover" src={img} />
            </div>
        </motion.li>
    );
}
