import React from "react";

function HubSpotIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 137 40"
        >
            <g clipPath="url(#clip0_1_547)">
                <path
                    fill="#33475B"
                    d="M14.813 6.418v10.936H4.766V6.418H0v26.105h4.768V21.942h10.044v10.58h4.769V6.419h-4.768zM34.84 24.085a3.945 3.945 0 01-7.89 0V12.903h-4.523v11.182a8.459 8.459 0 1016.92 0V12.903H34.84v11.182zm33.511-10.03c0-2.293 1.516-3.019 3.177-3.019 1.34 0 3.107 1.02 4.265 2.255l2.96-3.491c-1.478-2-4.477-3.382-6.932-3.382-4.907 0-8.456 2.874-8.456 7.637 0 8.84 10.803 6.034 10.803 10.981 0 1.525-1.481 2.872-3.177 2.872-2.675 0-3.542-1.307-4.77-2.69l-3.288 3.418c2.103 2.58 4.697 3.892 7.803 3.892 4.662 0 8.411-2.909 8.411-7.456 0-9.811-10.803-6.762-10.803-11.017m66.838 14.297c-2.672 0-3.432-1.156-3.432-2.926v-7.839h4.155v-3.972h-4.155v-5.24l-4.586 2.059V26.4c0 4.084 2.818 6.142 6.681 6.142a9.428 9.428 0 001.809-.145l1.119-4.118c-.504.035-1.084.07-1.591.07M52.057 13.032c-2.24 0-3.804.65-5.316 2.133V6.574h-4.53v15.927c0 5.96 4.31 10.046 9.154 10.046 5.373 0 10.1-4.157 10.1-9.755 0-5.528-4.35-9.755-9.408-9.755m-.029 14.951a5.14 5.14 0 110-10.28 5.14 5.14 0 010 10.28zm49.216-5.433c0-5.608-4.716-9.755-10.1-9.755-4.842 0-9.153 4.084-9.153 10.046v15.936h4.529v-8.604c1.51 1.48 3.076 2.133 5.314 2.133 5.058 0 9.41-4.227 9.41-9.756zm-4.29-.057a5.14 5.14 0 11-10.28 0 5.14 5.14 0 0110.28 0z"
                ></path>
                <path
                    fill="#FF7A59"
                    d="M115.88 12.581v-4.55a3.502 3.502 0 002.021-3.16v-.104a3.505 3.505 0 00-3.505-3.504h-.104a3.507 3.507 0 00-3.505 3.504v.105a3.51 3.51 0 002.021 3.158v4.551a9.92 9.92 0 00-4.719 2.078L95.608 4.937c.089-.32.135-.651.14-.983a3.948 3.948 0 10-3.953 3.94 3.906 3.906 0 001.944-.528l12.291 9.564a9.957 9.957 0 00.152 11.213l-3.738 3.739a3.21 3.21 0 00-.934-.152 3.244 3.244 0 00-2.29 5.535 3.242 3.242 0 005.535-2.29 3.221 3.221 0 00-.152-.934l3.698-3.7a9.972 9.972 0 107.579-17.76zm-1.533 14.968a5.12 5.12 0 01-4.854-3.124 5.116 5.116 0 014.854-7.104 5.117 5.117 0 01.005 10.225"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1_547">
                    <path fill="#fff" d="M0 0H136.774V40H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default HubSpotIcon;
