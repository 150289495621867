import React from "react";

function AdSpendIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 118 84"
        >
            <path
                stroke="#fff"
                strokeWidth="3"
                d="M38.226 47.721l72.619-28.618a39.052 39.052 0 01-9.724 41.004 39.06 39.06 0 01-55.226-.956 39.055 39.055 0 01-7.669-11.43z"
            ></path>
            <path
                fill="#fff"
                fillOpacity="0.5"
                d="M15.808 48.172a24.829 24.829 0 0146.198-18.207l-23.099 9.103-23.099 9.104z"
            ></path>
        </svg>
    );
}

export default AdSpendIcon;
