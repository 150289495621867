import React, { ReactElement } from "react";
import { GetStaticProps } from "next";
import SeveralBrandsWrapper from "@components/layouts/severalBrandsWrapper";
import FirstSection from "@components/severalbrands/homePage/firstSection";
// import SecondSection from "@components/severalbrands/homePage/secondSection";
import ThirdSection from "@components/severalbrands/homePage/thirdSection";
import ForthSection from "@components/severalbrands/homePage/forthSection";
import { DomainInfo } from "@components/severalbrands/utils/services";
import initialCall from "@components/severalbrands/utils/initialCall";
import MetaTags from "@components/severalbrands/metaTags";
import { getFinalLogosForLogoSLider } from "@components/severalbrands/utils";
import LogoSlider from "@components/severalbrands/logoSlider";
import {
    getDescriptionForHomePage,
    getKeyWordsForMetaTags,
} from "@components/severalbrands/utils";
export default function SeveralBrandsHome({
    domain,
}: {
    domain: DomainInfo;
}): ReactElement {
    const finalLogos = getFinalLogosForLogoSLider(
        domain.config?.sliderLogos,
        null,
    );
    return (
        <SeveralBrandsWrapper domainInfo={domain}>
            <MetaTags
                title={domain?.title ?? "Several Brands LLC"}
                description={getDescriptionForHomePage(domain)}
                keyWords={getKeyWordsForMetaTags(domain)}
            />
            <FirstSection />
            {finalLogos?.length > 0 && (
                <LogoSlider
                    logos={finalLogos}
                    animationDuration={20}
                    showButton
                />
            )}
            {/* <SecondSection domain={domain} /> */}
            <ThirdSection />
            <ForthSection />
        </SeveralBrandsWrapper>
    );
}
//@ts-ignore
export const getStaticProps: GetStaticProps = async () => {
    return await initialCall("HomePage");
};
