import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";
import ShareSlider from "../sharedSlider";
import HubSpotIcon from "./icons/hubSpotIcon";
import BrightTalkIcon from "./icons/brightTalkIcon";
import MarketingDonutIcon from "./icons/marketingDonutIcon";
import SemrushIcon from "./icons/semrushIcon";
const list = [
    {
        description:
            "According to HubSpot, companies that focus on generating leads experience a 133% higher return on investment than those that don't.",
        mainLogo: <HubSpotIcon />,
    },
    {
        description:
            "According to a survey by BrightTALK, 57% of B2B marketers find generating high-quality leads a significant challenge.",
        mainLogo: <BrightTalkIcon />,
    },
    {
        description:
            "According to Marketing Donut, businesses are not able to follow up on 80% of leads, resulting in a massive market of missed potential, opportunities, and sales.",
        mainLogo: <MarketingDonutIcon />,
    },
    {
        description:
            "Attracting quality leads with content remains the top challenge for 45% of marketers, with 38% struggling to allocate sufficient resources.",
        mainLogo: <SemrushIcon />,
    },
];
export default function WhyOutsource() {
    const mainSectionDevRef = useRef(null);
    const isInView = useInView(mainSectionDevRef, { once: true });
    const firstEl = useAnimation();

    useEffect(() => {
        if (isInView) {
            firstEl.start("visible").catch(() => {});
        }
    }, [isInView]);
    return (
        <motion.section
            ref={mainSectionDevRef}
            className="flex flex-col  lg:flex-row items-center  gap-5 lg:mt-60 mt-36 overflow-hidden  py-4"
            variants={{
                visible: {
                    y: [150, 0],
                    transition: { duration: 1.5 },
                },
            }}
            animate={firstEl}
        >
            <div className="3xl:max-w-[600px]  lg:max-w-[410px] z-10 ">
                <h2 className="font-bold 3xl:text-7xl 3xl:leading-[5rem] lg:text-5xl text-3xl lg:leading-[54px] leading-9  text-center lg:text-left">
                    Why Outsource Your Lead Generation?
                </h2>
            </div>
            <div className="sm:flex z-10  items-center justify-center w-full     ">
                <ShareSlider deferentStyle={true} list={list} perPage={2} />
            </div>
        </motion.section>
    );
}
