import React from "react";
import styles from "./style.module.scss";
import WeAreGoodWithNumbers from "./weAreGoodWithNumbers";
import WhatClientsSay from "./whatClientsSay";
import WhyOutsource from "./whyOutsource";
import B2BSlides from "./b2bSlides";
export default function ThirdSection() {
    return (
        <section className={styles["bgStyle"]}>
            <div className="lg:py-36 py-20">
                <WeAreGoodWithNumbers />
                <WhatClientsSay />
                <WhyOutsource />
                <B2BSlides />
                <div className="h-[3800px] hidden lg:block "></div>
            </div>
        </section>
    );
}
