import React from "react";

function SemrushIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="237"
            height="32"
            fill="none"
            viewBox="0 0 237 32"
        >
            <g clipPath="url(#clip0_1380_11212)">
                <g clipPath="url(#clip1_1380_11212)">
                    <path
                        fill="#000"
                        d="M162.68 12.277c0-3.855-2.371-6.662-6.919-6.662H141.07v19.99h4.825v-6.822h5.714l5.595 6.82h5.437v-.433l-5.437-6.506c3.421-.692 5.476-3.184 5.476-6.387zm-7.453 2.472h-9.313V9.767h9.313c1.72 0 2.827.91 2.827 2.491 0 1.62-1.067 2.49-2.827 2.49zm80.392-9.134h-4.529v7.592h-12.159V5.615h-4.883v20.01h4.883v-7.89h12.159v7.89h4.529V5.614zm-107.458 0l-5.379 16.825h-.296l-5.397-16.825h-8.603v20.01h4.606V9.192h.277l5.378 16.431h7.415l5.398-16.431h.276v16.431h4.746V5.615h-8.424.003zM75.608 13.8c-1.701-.176-4.884-.473-6.584-.651-1.68-.178-2.65-.673-2.65-1.78 0-1.067 1.028-1.958 5.18-1.958 3.678 0 7.058.792 10.005 2.216v-4.47c-2.945-1.403-6.21-2.095-10.263-2.095-5.693 0-9.628 2.372-9.628 6.406 0 3.401 2.313 5.26 6.94 5.773 1.68.18 4.527.435 6.525.593 2.174.18 2.808.85 2.808 1.84 0 1.364-1.524 2.194-5.378 2.194-3.916 0-7.89-1.286-10.717-3.084v4.606c2.274 1.524 6.19 2.808 10.558 2.808 6.208 0 10.203-2.392 10.203-6.683.039-3.242-2.096-5.2-7-5.713l.001-.002zM85.81 5.615v20.01h18.644v-4.096H90.416v-4.052h13.8v-4.054h-13.8V9.707h14.039V5.615H85.808h.001zM204.063 13.8c-1.701-.176-4.884-.473-6.584-.651-1.681-.178-2.649-.673-2.649-1.78 0-1.067 1.028-1.958 5.18-1.958 3.677 0 7.058.792 10.004 2.216v-4.47c-2.946-1.403-6.208-2.095-10.261-2.095-5.695 0-9.629 2.372-9.629 6.406 0 3.401 2.313 5.26 6.939 5.773 1.681.18 4.529.435 6.525.593 2.175.18 2.808.85 2.808 1.84 0 1.364-1.522 2.194-5.378 2.194-3.914 0-7.888-1.286-10.716-3.084v4.606c2.273 1.524 6.189 2.808 10.557 2.808 6.209 0 10.203-2.392 10.203-6.683.04-3.242-2.096-5.2-6.999-5.713v-.002zm-22.045-8.184v10.242c0 3.875-2.353 6.01-5.892 6.01-3.559 0-5.892-2.096-5.892-6.07V5.615h-4.804v9.727c0 7.256 4.527 10.855 10.775 10.855 5.991 0 10.638-3.44 10.638-10.617V5.615h-4.825z"
                    ></path>
                    <path
                        fill="#FF642D"
                        d="M45.137 15.501c0 .97-.493 1.126-1.72 1.126-1.303 0-1.54-.217-1.68-1.205-.257-2.512-1.957-4.647-4.803-4.885-.91-.077-1.128-.414-1.128-1.541 0-1.047.159-1.541.97-1.541 4.942.019 8.361 3.992 8.361 8.046zm7.198 0C52.335 7.967 47.235 0 35.45 0H12.042c-.476 0-.772.238-.772.673 0 .236.178.454.337.572.849.673 2.115 1.424 3.775 2.255a55.246 55.246 0 004.152 1.858c.533.217.732.455.732.751 0 .416-.297.673-.87.673H.812c-.555 0-.812.356-.812.711 0 .296.099.554.356.81 1.502 1.583 3.914 3.481 7.416 5.676 3.196 1.985 6.496 3.8 9.884 5.436.514.238.672.515.672.81-.019.337-.276.614-.87.614H8.936c-.474 0-.75.257-.75.653 0 .217.178.493.415.712 1.936 1.76 5.042 3.678 9.174 5.436 5.517 2.353 11.11 3.757 17.399 3.757 11.922 0 17.16-8.917 17.16-15.896zm-15.56 11.131c-6.09 0-11.19-4.963-11.19-11.15 0-6.091 5.081-10.994 11.19-10.994 6.248 0 11.15 4.883 11.15 10.994-.019 6.188-4.903 11.15-11.15 11.15z"
                    ></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1380_11212">
                    <path fill="#fff" d="M0 0H236.227V31.397H0z"></path>
                </clipPath>
                <clipPath id="clip1_1380_11212">
                    <path fill="#fff" d="M0 0H236.227V31.397H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default SemrushIcon;
