import React from "react";

function ClientsNumbersIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 147 147"
        >
            <circle
                cx="68.986"
                cy="70.645"
                r="18"
                fill="#fff"
                transform="rotate(-9.186 68.986 70.645)"
            ></circle>
            <path
                fill="#fff"
                fillOpacity="0.5"
                d="M109.958 43.883a46.55 46.55 0 0110.231 34.133 46.549 46.549 0 01-16.901 31.371 46.546 46.546 0 01-34.133 10.231 46.567 46.567 0 01-31.37-16.901L73.87 73.3l36.087-29.417z"
            ></path>
        </svg>
    );
}

export default ClientsNumbersIcon;
